<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
          >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>代理管理</el-breadcrumb-item>
          <el-breadcrumb-item>分润汇总</el-breadcrumb-item>
          <el-breadcrumb-item>BD分润</el-breadcrumb-item>
          <el-breadcrumb-item>BD分润明细</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="分润规则">
              <el-select
                      v-model="where.obj.billType"
                      placeholder="请选择"
                      filterable
                      clearable
              >
                <el-option label="全部" value="0"></el-option>
                <el-option label="惠豆分润" value="1"></el-option>
                <el-option label="聚合分润" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input
                      v-model="where.obj.keyword"
                      placeholder="请输入姓名/手机号/ID"
                      clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="日期筛选">
              <el-date-picker
                      v-model="date"
                      @change="orderDate" @input="immediUpdate"
                      type="daterange"
                      range-separator="~"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                                      start-placeholder="起始日期"
                      end-placeholder="截止日期">
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
              >条件筛选</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12"> </el-col>
            <el-col :span="12">
              <el-button
                      type="primary"
                      @click="exportData"
                      disabled
                      icon="el-icon-tickets"
              >导出数据</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-row style="display: inline-block">
          <el-col class="page-remark">
            <span>说明：</span>
            <p>
              BD发展的所有商家门店，使用惠豆分润规则的每笔交易，以及每笔交易的分润金额
            </p>
          </el-col>
          <el-col>
            <el-table
                    :data="list"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.1)"
                    @selection-change="handleSelectionChange"
            >
              <el-table-column prop="bdName" label="BD姓名"></el-table-column>
              <el-table-column prop="serviceName" label="所属门店"></el-table-column>
              <el-table-column prop="serviceArea" label="交易编号"></el-table-column>
              <el-table-column prop="month" label="交易时间"></el-table-column>
              <el-table-column prop="tradeNum" label="交易金额"></el-table-column>
              <el-table-column prop="tradeAmount" label="赠豆比例"></el-table-column>
              <el-table-column prop="sendHuiDou" label="送出惠豆"></el-table-column>
              <el-table-column prop="huiDoAmount" label="实际分润金额"></el-table-column>
            </el-table>
          </el-col>
          <el-col>
            <div class="admin_table_main_pagination">
              <el-pagination
                      @current-change="currentChange"
                      @size-change="handleSizeChange"
                      background
                      :total="totalData"
                      :page-size="pageSize"
                      :current-page="currentPage"
                      :page-sizes="[10, 20, 50, 100]"
                      layout="sizes, prev, pager, next, total, jumper"
              ></el-pagination>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {
        list: [],
        date: "",
        row: {},
        loading: false,
        totalData: 0, // 总条数
        pageSize: 20,
        currentPage: 0,
        selectId: "",
        where: {
          page: 0,
          limit: 0,
          obj:{
            "billType": '0',
            "endDate": "",
            "keyword": "",
            "startDate": "",
            "tradeTimeAsc": true,
            "traderId": this.$route.query.id,
          },
        },
      };
    },
    methods: {
      listData() {
        this.loading = true;
        this.where.page = this.currentPage;
        this.where.limit = this.pageSize;
        this.$post(this.$api.extensionWorkersDetail, this.where).then((res) => {
          this.totalData = res.data.total;
          this.list = res.data.records;
          this.loading = false;
        });
      },
      handleSelectionChange(e) {
        let ids = [];
        e.forEach((v) => {
          ids.push(v.id);
        });
        this.selectId = ids.join(",");
      },
      currentChange(e) {
        this.currentPage = e;
        this.listData();
      },
      handleSizeChange(e) {
        this.pageSize = e;
        this.listData();
      },
      orderDate(obj) {
        if (obj) {
          this.where.obj.startDate = obj[0];
          this.where.obj.endDate = obj[1];
          console.log(obj);
        }
      },
      immediUpdate(e) {
        this.$nextTick(() => {
          console.log(e)
          if(e==null){
            this.where.obj.startDate = '';
            this.where.obj.endDate = '';
          }
        });
      },
      exportData() {
        // console.log("exportData");
      },
    },
    created() {
      this.listData();
      this.getOpenAreaList();
    },
  };
</script>
<style lang="scss" scoped>
  .endDate {
    color: #c0c4cc;
  }
  .el-image {
    height: 100px;
    width: 100px;
  }
  .el-select {
    width: 120px;
  }
</style>
